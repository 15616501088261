
$orbitItemSize: 1.6em;
$map: (ring-0: 4, ring-1: 3, ring-2: 8, ring-3: 3);
$lastRing: 3;

.orbit {
  float: left;
  width: 100%;
}

.orbit-icon {
  width: $orbitItemSize;
  height: $orbitItemSize;
  line-height: $orbitItemSize;
  font-size: 1.2em;
  border-radius: 50%;
  background: #ccc;
  color: #fff;
  text-align: center;
  display: block;
}

.orbit-wrap {
  height: 28em;
  list-style: none;
  font-size: 1.3em;
  position: relative;
  
  > li {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    &:hover {
      ul {
        border-width: 2px;
        border-color: #fff;
      }
      ~ li ul {
        border-color: rgba(255, 255, 255, 0.2);
        li {
          opacity: 0.4;          
        }
      }
    }
    
  }
}

@media only screen and (max-width: 600px) {
  .orbit-wrap {
    font-size: 1em;
  }
}

ul[class^=ring] {
  @extend %ring;
  transition: all 300ms ease-in-out;
  li {
    @extend %orbiting-object;
    transition: all 300ms ease-in-out;
  }
}

@mixin orbit-item($numItems, $numRing) {
  @for $s from 1 through $numItems {  
    // Spread items over the ring
    $deg: 380deg / $numItems;
    
    .ring-#{$numRing} > *:nth-of-type(#{$s}) {
      transform: rotate($s * $deg) translate(12.5em - ($numRing * 2.5)) rotate(-$s * $deg);
    }
  }
}

// Render rings
@for $i from 0 through $lastRing {  
  .ring-#{$i} {
    // decrease each ring in size
    width: 25em - ($i * 5);
    height: 25em - ($i * 5);
    animation: clockwiseRotate 35s - ($i * 5) linear infinite;
    
    i {
      animation: counterClockwiseRotate 35s - ($i * 5) linear infinite;
    }
  }
  
  @include orbit-item(map-get($map, ring-#{$i}), $i);
}


%ring {
  border: solid 1px rgba(33, 150, 243, 0.8);
  position: relative;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  box-sizing: content-box;
}

%orbiting-object {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: $orbitItemSize;
  height: $orbitItemSize;
  margin: -$orbitItemSize / 2;
}

/*
  center;
*/
.orbit-center {
  z-index: 5;
  font-size: 2em;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em;
  text-align: center;
  background: hotpink;
  border-radius: 50%;
  
  &:hover .orbit-center__icon {
    transform: rotateZ(0deg);
  }
}
.orbit-center__icon {
  transform: rotateZ(-360deg);
  transition: all 300ms ease-in-out;
}
.orbit-wrap > li.orbit-center:hover ~ li > ul {
  width: 0;
  height: 0;
  * {
    transform: translate(0, 0);
  }
}

/* 
animations 
*/
@keyframes clockwiseRotate {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(360deg)
    }
}

@keyframes counterClockwiseRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
      transform: rotate(-360deg);
  }
}

/* 
icons 
*/
$icons : (
  ('heart-o', #b71c1c, white),
  ('coffee', #4CAF50, #CDDC39),
  ('safari', white, darkslateblue),
  ('firefox', #1565c0, #ff8f00),
  ('chrome', #fbc02d, #ef6c00),
  ('edge', #03a9f4, white),
  ('android', snow, limegreen),
  ('apple', #444, white),
  ('linux', white, #222),
  ('windows', white, #03a9f4),
  ('terminal', #222, #fff),
  ('html5', white, #e65100),
  ('css3', white, #2196f3),
  ('git', #222, white),
  ('free-code-camp', green, white),
  ('meetup', red, white),
  ('podcast', red, white),
  ('codepen', #333, white)
);

@each $icon in $icons {
  .fa-#{nth($icon, 1)} {
    background: nth($icon, 2);
    color: nth($icon, 3);
  }
}



